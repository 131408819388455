import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import gunSale from '../pdfs/GunSale.pdf';
import open_sans_font from '../OpenSans-VariableFont_wdth,wght.ttf';
import { uploadPdfAndGetUrl, fetchSignatureFromStorage } from '../components/firebase'; // Import the upload function

const EnglishOrHebrew = (text) => {
  if (!text) return '';
  const hebrewRegex = /[\u0590-\u05FF]/;
  const englishRegex = /[a-zA-Z]/;
  if (hebrewRegex.test(text)) {
    return text;
  } else if (englishRegex.test(text)) {
    return text.split('').reverse().join('');
  } else {
    return '';
  }
};
const generateGunSale = async (formData) => {
  const existingPdfUrl = gunSale;
  const existingPdfBytes = await fetch(existingPdfUrl).then(res => res.arrayBuffer());
  console.log("data", formData);
  const pdfDoc = await PDFDocument.load(existingPdfBytes);

  // Register fontkit to enable custom font embedding
  pdfDoc.registerFontkit(fontkit);

  // Load a font that supports Hebrew characters
  const fontUrl = open_sans_font;
  const fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer());
  const hebrewFont = await pdfDoc.embedFont(fontBytes);

  const pages = pdfDoc.getPages();
  const firstPage = pages[0];
  // rangeID_currentYear
  const rangeId = formData.rangeId + '_' + formData.registrationDate.split(',')[0].split('.')[2];
  const date = formData.registrationDate || '';

  const fullName = formData.fullName || '';
  const teudatZehut = formData.teudatZehut || '';
  const address = formData.city || '';
  const phone = formData.phoneNumber || '';

  const menufacturer = formData.pistolManufacturer || '';
  const origin = formData.origin || '';
  const model = formData.model || '';

  const imprintOnPistol = formData.imprintOnPistol || '';
  const caliber = formData.caliber || '';

  const license = formData.gunLicenseId || '';
  const quantity = formData.ammoQuantity || '';

  const reverse = (str) => {
    return str.split('').reverse().join('');
  }

  let signatureUrl = formData.signatureUrl || null;
  if (!signatureUrl) {
    signatureUrl = await fetchSignatureFromStorage(rangeId);
  }
  try {
    firstPage.drawText(rangeId?.toString(), { x: 80, y: 708, size: 10, font: hebrewFont, color: rgb(0, 0, 0) });

    firstPage.drawText(date?.toString(), { x: 400, y: 641, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
    firstPage.drawText(license?.toString(), { x: 150, y: 641, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });

    firstPage.drawText(fullName?.toString(), { x: 420, y: 545, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });
    firstPage.drawText(teudatZehut?.toString(), { x: 305, y: 545, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });
    // firstPage.drawText(address?.toString(), { x: 230, y: 572, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });
    firstPage.drawText(phone?.toString(), { x: 150, y: 545, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });

    firstPage.drawText(menufacturer?.toString(), { x: 300, y: 428, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });
    // firstPage.drawText(origin?.toString(), { x: 230, y: 488, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });
    firstPage.drawText(model?.toString(), { x: 150, y: 428, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });

    firstPage.drawText(imprintOnPistol?.toString(), { x: 440, y: 375, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });
    firstPage.drawText(caliber?.toString(), { x: 310, y: 375, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });



  } catch (error) {
    console.error('Error drawing text on PDF page:', error);
  }


  // Add the signature
  if (signatureUrl) {
    const signatureImage = await pdfDoc.embedPng(signatureUrl);
    firstPage.drawImage(signatureImage, { x: 85, y: 120, width: 150 * 1.1, height: 50 * 1.1 });
  }

  const pdfBytes = await pdfDoc.save();
  const downloadUrl = await uploadPdfAndGetUrl(pdfBytes, 'gun_sales', `gun_sale_${rangeId}_${fullName}_${date}`);
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.download = `gun_sale_${rangeId}_${fullName}_${date}.pdf`;
  link.click();
  return downloadUrl;
};

export default generateGunSale;
