import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getFirestore, doc, getDoc, setDoc, addDoc, deleteDoc, collection, getDocs, updateDoc } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut as firebaseSignOut } from "firebase/auth";
import { getStorage, ref, uploadString, uploadBytesResumable, getDownloadURL, uploadBytes  } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD1oEB-ZclPDAVZaxHuaC0HfSsT9SAJ6ho",
  authDomain: "shaygunrange.firebaseapp.com",
  projectId: "shaygunrange",
  storageBucket: "shaygunrange.appspot.com",
  messagingSenderId: "178178639211",
  appId: "1:178178639211:web:f00dca5360a4c6cac9782e",
  measurementId: "G-JZ595B3LR5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const provider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, provider);
    return result.user;
  } catch (error) {
    console.error('Error during login:', error);
    throw error;
  }
};

const signOut = () => {
  return firebaseSignOut(auth)
    .then(() => {
      console.log('User signed out successfully');
    })
    .catch((error) => {
      console.error('Error during sign out:', error);
    });
};

const getUserRole = async (user) => {
  if (!user || !user.uid) {
    console.error('User object or UID is undefined');
    return null;
  }

  const userDoc = await getDoc(doc(db, 'users', user.uid));
  if (userDoc.exists()) {
    return userDoc.data().role;
  }
  return null;
};

const addMember = async (user, wantsUpdates) => {
  try {
    const userDoc = doc(db, 'users', user.uid);
    const userSnapshot = await getDoc(userDoc);

    if (!userSnapshot.exists()) {
      await setDoc(userDoc, {
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
        wantsUpdates: wantsUpdates,
        role: 'user' // Default role
      });
    } 
  } catch (error) {
    console.error('Error adding member to Firestore:', error);
    throw error;
  }
};


const addNonLoggedMember = async (userData,dateKey) => {
  try {
    // Generate a unique ID for the non-logged-in user
    const userId = userData.teudatZehut || `${userData.fullName}-${Date.now()}`;

    // Create the user document in the `users` collection
    await setDoc(doc(db, 'users', userId), userData, { merge: true });
    console.log('User data successfully saved in the users collection for non-logged-in user.');

    // Extract dateKey and rangeId from userData
    // const dateKey = userData.dateKey;
    const rangeId = userData.rangeId;
    console.log(dateKey,rangeId);
    // Reference to the specific document in `registrations -> dateKey -> rangeId`
    const registrationDocRef = doc(db, 'registrations', dateKey);

    // Update the document to delete `signatureUrl` for the specific rangeId
    await updateDoc(registrationDocRef, {
      [`${rangeId}.signatureUrl`]: {}
    });
    console.log('Signature URL successfully removed from the registration document for non-logged-in user.');

  } catch (error) {
    console.error('Error adding non-logged-in user or removing signature URL: ', error);
    logError(error, userData.teudatZehut, "addNonLoggedMember");
    throw error;
  }
};



const addSubscriber = async ({ email, name, mobile }) => {
  try {
    const docRef = doc(db, 'subscribers', email);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      await setDoc(docRef, { email, name, mobile });
      return 'success';
    } else {
      return 'exists';
    }
  } catch (error) {
    console.error('Error adding subscriber to Firestore:', error);
    return 'fail';
  }
};

const unsubscribe = async (email) => {
  try {
    const docRef = doc(db, 'subscribers', email);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      await deleteDoc(docRef);
      return 'success';
    } else {
      return 'not_found';
    }
  } catch (error) {
    console.error('Error removing subscriber from Firestore:', error);
    return 'fail';
  }
};

const initializeTextCollection = async (texts) => {
  const textsCollection = collection(db, 'texts');
  const querySnapshot = await getDocs(textsCollection);

  if (querySnapshot.empty) {
    for (const [key, value] of Object.entries(texts)) {
      await setDoc(doc(db, 'texts', key), { value });
    }
    console.log('Texts initialized in Firestore');
  } else {
    console.log('Texts already initialized in Firestore');
  }
};

const addEvent = async (event) => {
  const eventsRef = collection(db, 'events');
  await addDoc(eventsRef, event);
};

const getAnalyticsData = async () => {
  const analyticsRef = doc(db, 'analytics', 'current');
  const analyticsSnap = await getDoc(analyticsRef);
  return analyticsSnap.exists() ? analyticsSnap.data() : { totalEntries: 0, monthlyEntries: 0 };
};

const getSubscribers = async () => {
  const subscribersRef = collection(db, 'subscribers');
  const subscribersSnap = await getDocs(subscribersRef);
  return subscribersSnap.docs.map(doc => doc.data());
};

// Functions for handling the registration process

const saveSignature = async (teudatZehut, signatureData) => {
  const storageRef = ref(storage, `signatures/${teudatZehut}.png`);
  await uploadString(storageRef, signatureData, 'data_url');
  return await storageRef.getDownloadURL();
};

const createUserIfNotExists = async (userId, userData) => {
  const userDocRef = doc(db, 'users', userId);
  const userDocSnap = await getDoc(userDocRef);

  if (!userDocSnap.exists()) {
    // User doesn't exist, create a new document
    await setDoc(userDocRef, {
      ...userData,
      rangeId: await generateRangeId(), // Generate a unique range ID
      metadata: {
        firstRegistrationDate: new Date().toISOString(),
        lastActivity: new Date().toISOString(),
      },
    });
  } else {
    // User exists, update the last activity date
    await setDoc(userDocRef, {
      metadata: {
        lastActivity: new Date().toISOString(),
      },
    }, { merge: true });
  }
};


const generateRangeId = async () => {
  const today = new Date();
  const year = today.getFullYear().toString(); // Full year, e.g., '2024'

  // Reference the document that holds the counter for the current year
  const counterDocRef = doc(db, 'metadata', year);
  const counterDocSnap = await getDoc(counterDocRef);

  let counter = 1; // Start counter at 1 for the first client of the year
  if (counterDocSnap.exists()) {
    counter = counterDocSnap.data().counter + 1;
    await setDoc(counterDocRef, { counter });
  } else {
    await setDoc(counterDocRef, { counter: 1 });
  }

  // Format the rangeId as `counter/year`
  const rangeId = `${counter}`;

  return rangeId;
};



// Add Registration
const addRegistration = async (rangeId, registrationData) => {
  // Get the current date
  const today = new Date();
  
  // Determine if it's before or after 12 PM
  const timeSuffix = today.getHours() < 12 ? '08' : '12';
  
  // Create the dateKey in the format YYYY-MM-DD-08 or YYYY-MM-DD-12
  const dateKey = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}-${timeSuffix}`;
  
  const registrationDocRef = doc(collection(db, "registrations"), dateKey);
  
  // Save the registration data under the new dateKey
  await setDoc(registrationDocRef, {
    [rangeId]: {
      ...registrationData,
      dateKey: dateKey
    }
  }, { merge: true });
  return dateKey;
};

// Add New Activity
const createActivity = async (activityData) => {
  await addDoc(collection(db, 'activities'), activityData);
};

export const uploadPdfAndGetUrl = async (fileData, folder = 'pdfs', fileName = 'file') => {
  try {
    // Create a unique file name using the provided base name and a UUID
    const uniqueFileName = `${fileName}_${uuidv4()}.pdf`;
    const storageRef = ref(storage, `${folder}/${uniqueFileName}`);

    // Upload the file data
    await uploadBytes(storageRef, fileData);

    // Get the download URL of the uploaded file
    const downloadURL = await getDownloadURL(storageRef);

    return downloadURL;
  } catch (error) {
    console.error('Error uploading file to Firebase Storage:', error);
    throw new Error('Failed to upload file');
  }
};
export const savePdfUrlToRegistration = async (dateKey, rangeId,type, pdfUrl) => {
  try{
    const registrationDocRef = doc(db, 'registrations', dateKey);
    await updateDoc(registrationDocRef, {
      [`${rangeId}.${type}`]: pdfUrl
    });
    console.log('PDF URL successfully saved in the registration document');
  }
  catch (error) {
    console.error('Error saving PDF URL to registration:', error);
    throw error;
  }
};
  
const updateModelDetails = async (modelId, modelDetails) => {
  try {
    await setDoc(doc(db, 'models', modelId), modelDetails);
    console.log('Model details updated successfully');
  } catch (error) {
    console.error('Error updating model details:', error);
  }
};

async function logError(error, userId, page) {
  try {
    const logEntry = {
      timestamp: new Date().toISOString(),
      userId: userId || 'anonymous',
      type: 'error',
      message: error.message || 'Unknown error',
      stack: error.stack || '',
      page: page || 'Unknown page',
    };
    
    await addDoc(collection(db, 'logs'), logEntry);
  } catch (loggingError) {
    console.error("Failed to log the error:", loggingError);
  }
}

const fetchSignatureFromStorage = async (rangeId) => {
  try {
    // Construct the storage reference path for the signature
    const storageRef = ref(storage, `signatures/${rangeId}.png`);
    
    // Fetch the download URL
    const signatureUrl = await getDownloadURL(storageRef);
    
    return signatureUrl;
  } catch (error) {
    console.error('Error fetching signature from storage:', error);
    return null; // Return null if there's an error (e.g., file not found)
  }
};




export { analytics, db, auth, storage, provider, signInWithGoogle, signOut,
  getUserRole, addSubscriber, unsubscribe, addMember, addNonLoggedMember, initializeTextCollection,
  addEvent, getAnalyticsData, getSubscribers, logEvent, saveSignature, generateRangeId,
  createUserIfNotExists,createActivity, addRegistration, updateModelDetails, logError, fetchSignatureFromStorage};
