import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import AmmoSale from '../pdfs/gunAndAmmoSale.pdf';
import open_sans_font from '../OpenSans-VariableFont_wdth,wght.ttf';
import { uploadPdfAndGetUrl } from '../components/firebase'; // Import the upload function


const generateGunAmmoSale = async (formData) => {
  const existingPdfUrl = AmmoSale;
  const existingPdfBytes = await fetch(existingPdfUrl).then(res => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(existingPdfBytes);

  // Register fontkit to enable custom font embedding
  pdfDoc.registerFontkit(fontkit);

  // Load a font that supports Hebrew characters
  const fontUrl = open_sans_font;
  const fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer());
  const hebrewFont = await pdfDoc.embedFont(fontBytes);

  const pages = pdfDoc.getPages();
  const firstPage = pages[0];
  // rangeID_currentYear
  const rangeId = formData.rangeId+'_'+ formData.registrationDate.split(',')[0].split('.')[2];
  const date = formData.registrationDate.split(',')[0];
  const time = formData.registrationDate.split(',')[1];

  const fullName = formData.fullName || 'שם משתמש';
  const teudatZehut = formData.teudatZehut || '123456789';
  const phone = formData.phoneNumber || '123456789';
  const license = formData.gunLicenseId || '123456789';
  const imprintOnPistol = formData.imprintOnPistol || '123456789';
  const menufacturer = formData.pistolManufacturer || '';
  const origin = formData.origin || '';
  const model = formData.model || '';
  const caliber = formData.ammunitionCaliber || '9mm';
  const berrel = '1';

  const quantity = formData.bulletCount || '50';

  const signatureUrl = formData.signatureUrl || null;

 firstPage.drawText(rangeId, { x: 100, y: 685, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(date, { x: 200, y: 643, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(time, { x: 200, y: 630, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });

  firstPage.drawText(fullName, { x: 440, y: 540, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(teudatZehut, { x: 315, y: 540, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(phone, { x: 200, y: 540, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(license, { x: 100, y: 540, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });

  firstPage.drawText(menufacturer, { x: 330, y: 445, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(origin, { x: 220, y: 445, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(model, { x: 90, y: 445, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });

  firstPage.drawText(imprintOnPistol, { x: 440, y: 405, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(berrel, { x: 90, y: 405, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(caliber, { x: 220, y: 405, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(caliber, { x: 330, y: 405, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });



  firstPage.drawText(caliber, { x: 330, y: 235, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(quantity.toString(), { x: 250, y: 235, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
    


  // Add the signature
  if (signatureUrl) {
    const signatureImage = await pdfDoc.embedPng(signatureUrl);
    firstPage.drawImage(signatureImage, { x: 20, y: 30, width: 150*1.1, height: 50*1.1 });
  }
  firstPage.drawText('חתימת הלקוח :', { x: 200, y: 62, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(fullName, { x: 200, y: 50, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(teudatZehut, { x: 200, y: 40, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });

  const pdfBytes = await pdfDoc.save();
  const downloadUrl = await uploadPdfAndGetUrl(pdfBytes, 'gunAndAmmos', `gunAndAmmo_${teudatZehut}`);
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  const pdfFile = new File([blob], 'gunAndAmmoSale.pdf', { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(pdfFile);
  link.download = 'gunAndAmmoSale.pdf';
  link.click();

  return downloadUrl;
};

export default generateGunAmmoSale;
