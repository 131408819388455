import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where, orderBy, doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import '../styles/GunCollection.css';

const GunCollection = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedDate, setSelectedDate] = useState('all');
  const [firearmEntries, setFirearmEntries] = useState([]);

  useEffect(() => {
    const fetchFirearmEntries = async () => {
      try {
        let q;

        if (selectedDate === 'all') {
          q = query(collection(db, 'firearms'), orderBy('date', 'desc')); // Fetch all, ordered by date (newest first)
        } else {
          q = query(
            collection(db, 'firearms'),
            where('date', '==', selectedDate),
            orderBy('date', 'desc')
          );
        }

        const firearmCollection = await getDocs(q);
        const firearms = firearmCollection.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        setFirearmEntries(
          firearms
            .filter(entry => 
              entry.teudatZehut.includes(searchQuery) || 
              entry.phone.includes(searchQuery)
            )
            .sort((a, b) => {
              const [dayA, monthA, yearA] = a.date.split('/').map(Number);
              const [dayB, monthB, yearB] = b.date.split('/').map(Number);
              return new Date(yearB, monthB - 1, dayB) - new Date(yearA, monthA - 1, dayA);
            })
        );
      } catch (error) {
        console.error('Error fetching firearm entries:', error);
      }
    };

    fetchFirearmEntries();
  }, [selectedDate, searchQuery]);

  const handleCheckboxChange = async (id, checked) => {
    try {
      const firearmDocRef = doc(db, 'firearms', id);
      await updateDoc(firearmDocRef, { supplied: checked });
      setFirearmEntries(prevEntries =>
        prevEntries.map(entry =>
          entry.id === id ? { ...entry, supplied: checked } : entry
        )
      );
    } catch (error) {
      console.error('Error updating firearm entry:', error);
    }
  };

  const handleActivateMembership = async (teudatZehut, firearmId) => {
    try {
      const userDocRef = doc(db, 'users', teudatZehut);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const today = new Date();
        const membershipExpiration = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate()).toLocaleDateString('he-IL', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        });

        // Update the user document with membership and expiration
        await updateDoc(userDocRef, {
          membership: true,
          membershipExpiration: membershipExpiration,
        });

        // Update the corresponding firearm entry to indicate membership activation
        const firearmDocRef = doc(db, 'firearms', firearmId);
        await updateDoc(firearmDocRef, {
          membershipActivated: true,
        });

        // Update the UI state to reflect membership activation
        setFirearmEntries(prevEntries =>
          prevEntries.map(entry =>
            entry.id === firearmId ? { ...entry, membershipActivated: true } : entry
          )
        );

        console.log('Membership activated for:', teudatZehut);
      } else {
        console.error('User not found:', teudatZehut);
      }
    } catch (error) {
      console.error('Error activating membership:', error);
    }
  };

  const convertStringToDate = (dateStr) => {
    const [day, month, year] = dateStr.split('.').map(Number);
    return new Date(year, month - 1, day); // Month is 0-indexed in JavaScript Date
  };
  
  // Get unique, sorted dates for the dropdown
  const uniqueDates = Array.from(new Set(firearmEntries.map(entry => entry.date)))
    .sort((a, b) => convertStringToDate(b) - convertStringToDate(a));
  
  // Convert the sorted dates back to `DD.MM.YYYY` for display in the dropdown
  const sortedDisplayDates = uniqueDates.map(date => {
    const dateObj = convertStringToDate(date);
    return `${dateObj.getDate()}.${dateObj.getMonth() + 1}.${dateObj.getFullYear()}`;
  });
  
  return (
    <div className="gun-collection">
      <h2>איסופי נשק</h2>
      <div className="filter-header">
        <input
          type="text"
          placeholder="חפש לפי תז או מספר נייד"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <select value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)}>
          <option value="all">כל התאריכים</option>
          {sortedDisplayDates.map((date, index) => (
            <option key={index} value={date}>{date}</option>
          ))}
        </select>
      </div>
      
      <table className="guns-table">
        <thead>
          <tr>
            <th>שם</th>
            <th>תז</th>
            <th>נייד</th>
            <th>סוג</th>
            <th>תוצרת</th>
            <th>קובץ</th>
            <th>סופק</th>
            <th>תאריך</th>
            <th>חברות</th> {/* New Column for Membership Activation */}
          </tr>
        </thead>
        <tbody>
          {firearmEntries.length > 0 ? (
            firearmEntries.map((entry, index) => (
              <React.Fragment key={index}>
                {index > 0 && firearmEntries[index - 1].date !== entry.date && (
                  <tr className="date-divider">
                    <td colSpan="10">{entry.date}</td>
                  </tr>
                )}
                <tr>
                  <td>{entry.name}</td>
                  <td>{entry.teudatZehut}</td>
                  <td>{entry.phone}</td>
                  <td>{entry.type}</td>
                  <td>{entry.manufacturer}</td>
                  <td>
                    <a href={entry.pdfUrl} target="_blank" rel="noreferrer">צפה בקובץ</a>
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={entry.supplied || false}
                      onChange={(e) => handleCheckboxChange(entry.id, e.target.checked)}
                    />
                  </td>
                  <td>{entry.date}</td>
                  <td>
                    {entry.membershipActivated ? (
                      <button className="button-disabled" disabled>
                        חברות פעילה
                      </button>
                    ) : (
                      <button
                        className="activate-membership-button"
                        onClick={() => handleActivateMembership(entry.teudatZehut, entry.id)}
                      >
                        הפעל חברות
                      </button>
                    )}
                  </td> {/* Button for Membership Activation */}
                </tr>
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan="10">לא נמצאו רשומות</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default GunCollection;
